html,
body {
  font-family: 'Gilroy', Helvetica, sans-serif, system-ui;
  color-scheme: auto;
}

.enableScroll {
  touch-action: unset !important;
  overflow: auto !important;
  overscroll-behavior: unset !important;
}

body > img {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.chakra-avatar__group div:not(:first-child) {
  margin-inline-end: -4px;
  z-index: 1;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

// #__CONNECTKIT__ {
//   z-index: 89;
//   position: relative;
// }

/*fix for wallet connect v2 modal*/
.chakra-modal__overlay {
  z-index: 86 !important;
}
.chakra-modal__content-container {
  z-index: 87 !important;
}

.chakra-modal__content {
  z-index: 88 !important;
}

// #__CONNECTKIT__ {
//   div[role="dialog"]
//     > div:last-child
//     > div:last-child
//     > div:last-child
//     > div:not(.active-scale-up)
//     > div
//     > div {
//     div:first-child {
//       &:not(.enable) > button {
//         pointer-events: none;
//         opacity: 0.5;
//       }

//       button span {
//         font-size: 12px;
//       }
//     }
//     div:nth-child(2) {
//       display: none;
//     }
//   }
// }

.faqAnswer span {
  color: inherit !important;
  font-family: inherit !important;
}
